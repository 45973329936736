// ** React Imports
import {RouterOutlet} from "react-router-outlet"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@core/layouts/VerticalLayout"

// ** Menu Items Array
import navigation from "navigation/vertical"
import {User} from "react-feather"
import {useSelector} from "react-redux"

const customerBalance = (username) => {
    if (!username) return []
    return [
        {
            header: "Orders",
            resource: "customer-profile",
            action: "read",
        },
        {
            id: "profile",
            title: "Orders",
            icon: <User size={20} />,
            navLink: `/customers/${username.toUpperCase()}/view`,
            resource: "customer-profile",
            action: "read",
        },
    ]
}

const removeItemsArray = ["users", "orders"]
const filteredNavigation = navigation.filter(
    (el) => !removeItemsArray.includes(el.resource),
)

const VerticalLayout = (props) => {
    // ** For ServerSide navigation
    // useEffect(() => {
    //   axios.get(URL).then(response => setMenuData(response.data))
    // }, [])
    const role = useSelector((state) => state.auth.userData.role)
    const userName = useSelector((state) => state.auth.userData.username)
    // const customerUseOnly = role === "customer" ? [customerBalance(userName), ...navigation.filter(el => el.resource !== "users")].flat() : navigation
    const customerUseOnly =
        role === "customer"
            ? [customerBalance(userName), ...filteredNavigation].flat()
            : navigation

    return (
        <Layout menuData={customerUseOnly} {...props}>
            <RouterOutlet routes={props.routes} />
        </Layout>
    )
}

export default VerticalLayout
