// ** React Imports
// ** Router imports
import {Fragment, lazy} from "react"
import {matchPath, Redirect} from "react-router-dom"

// ** Routes Imports
import FinancialRoutes from "./Financial"
import OrderingRoutes from "./Ordering"
import CargoloopRoutes from "./Cargoloop"
import UserRoutes from "./Users"
import LocationsRoutes from "./Locations"
import ReferenceRoutes from "./References"

import AuthenticationRoutes from "./Authentication"
import PagesRoutes from "./Pages"

// ** Layouts
import BlankLayout from "@core/layouts/BlankLayout"
import VerticalLayout from "layouts/VerticalLayout"
import HorizontalLayout from "layouts/HorizontalLayout"
import LayoutWrapper from "@core/layouts/components/layout-wrapper"

// ** Route Components
import PublicRoute from "@core/components/routes/PublicRoute"
import PrivateRoute from "@core/components/routes/PrivateRoute"

// ** Utils
import {getHomeRouteForLoggedInUser, getUserData, isObjEmpty} from "utility/utils"

// ** Components
const Error = lazy(() => import("views/misc/Error"))
const Login = lazy(() => import("views/authentication/Login"))
const NotAuthorized = lazy(() => import("views/misc/NotAuthorized"))

// ** Document title
const TemplateTitle = "%s - Vuexy React Admin Template"

// ** Default Route
const DefaultRoute = "/orders"
// const

// ** Merge Routes
const Routes = [...FinancialRoutes, ...OrderingRoutes, ...CargoloopRoutes, ...UserRoutes, ...LocationsRoutes, ...ReferenceRoutes,

    ...AuthenticationRoutes, ...PagesRoutes]

const getRouteMeta = route => {
    if (isObjEmpty(route.element.props)) {
        if (route.meta) {
            return {routeMeta: route.meta}
        } else {
            return {}
        }
    }
}

const getHomeRoute = () => {
    const user = getUserData()
    if (user) {
        return getHomeRouteForLoggedInUser(user.role, user.username)
    } else {
        return "/login"
    }
}

const getRoutes = (layout, path) => {
    const defaultLayout = layout || "vertical"

    let defaultRoutes = [{
        path: "/", component: () => <Redirect replace to={getHomeRoute()} />,
    }, {
        path: "/login", component: () => <Login />,
    }, {
        path: "/auth/not-auth", component: () => <NotAuthorized />,
    }, {
        path: "*", component: () => <Error />,
    }]
    let currentRoute = Routes.filter(r => {
        const match = matchPath(path, {
            path: r.path, exact: true,
        })
        return match
    })[0]
    if (currentRoute) {
        layout = currentRoute.meta?.layout ?? layout
    } else {
        layout = "blank"
    }

    let routes = Routes.map(route => {
        var isBlank = false
        let RouteTag = PrivateRoute
        // ** Check for public or private route
        if (route.meta) {
            isBlank = route.meta.layout === "blank"
            RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
            const Wrapper = // eslint-disable-next-line multiline-ternary
                isObjEmpty(route.element.props) && !isBlank ? // eslint-disable-next-line multiline-ternary
                    LayoutWrapper : Fragment

            route.element = (<Wrapper {...(!isBlank ? getRouteMeta(route) : {})}>
                <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>)
        }

        return {
            path: route.path, component: () => route.element,
        }
    })

    const layoutRoutes = [...routes, ...defaultRoutes]
    const getLayout = {
        blank: <BlankLayout routes={layoutRoutes} />,
        vertical: <VerticalLayout routes={layoutRoutes} />,
        horizontal: <HorizontalLayout routes={layoutRoutes} />,
    }

    let layoutItem = getLayout[layout] || getLayout[defaultLayout]

    return layoutItem
}

export {DefaultRoute, TemplateTitle, Routes, getRoutes}
