// // source: projects/erp/service/api/v1/services/vehicles.proto
// /**
//  * @fileoverview
//  * @enhanceable
//  * @suppress {missingRequire} reports error on implicit type usages.
//  * @suppress {messageConventions} JS Compiler reports an error if a variable or
//  *     field starts with 'MSG_' and isn't a translatable message.
//  * @public
//  */
// // GENERATED CODE -- DO NOT EDIT!
// /* eslint-disable */
// // @ts-nocheck
//
// var jspb = require('google-protobuf');
// var goog = jspb;
// var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);
//
// var projects_erp_service_api_v1_model_vehicle_pb = require('./vehicle_pb.js');
// goog.object.extend(proto, projects_erp_service_api_v1_model_vehicle_pb);
// goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest', null, global);
// goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest', null, global);
// goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse', null, global);
// goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload', null, global);
// goog.exportSymbol('proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest', null, global);
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest = function(opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   /**
//    * @public
//    * @override
//    */
//   proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest';
// }
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse = function(opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.repeatedFields_, null);
// };
// goog.inherits(proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   /**
//    * @public
//    * @override
//    */
//   proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.displayName = 'proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse';
// }
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest = function(opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   /**
//    * @public
//    * @override
//    */
//   proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest';
// }
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest = function(opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   /**
//    * @public
//    * @override
//    */
//   proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.displayName = 'proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest';
// }
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload = function(opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   /**
//    * @public
//    * @override
//    */
//   proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.displayName = 'proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload';
// }
//
//
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
// /**
//  * Creates an object representation of this proto.
//  * Field names that are reserved in JavaScript and will be renamed to pb_name.
//  * Optional fields that are not set will be set to undefined.
//  * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//  * For the list of reserved names please see:
//  *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
//  * @param {boolean=} opt_includeInstance Deprecated. whether to include the
//  *     JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @return {!Object}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
//   return proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.toObject(opt_includeInstance, this);
// };
//
//
// /**
//  * Static version of the {@see toObject} method.
//  * @param {boolean|undefined} includeInstance Deprecated. Whether to include
//  *     the JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} msg The msg instance to transform.
//  * @return {!Object}
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.toObject = function(includeInstance, msg) {
//   var f, obj = {
//     page: jspb.Message.getFieldWithDefault(msg, 1, 0),
//     perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
//     sort: jspb.Message.getFieldWithDefault(msg, 3, ""),
//     sortColumn: jspb.Message.getFieldWithDefault(msg, 4, ""),
//     search: jspb.Message.getFieldWithDefault(msg, 5, ""),
//     nonorderedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
//     terminalName: jspb.Message.getFieldWithDefault(msg, 7, ""),
//     cancelledOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
//   };
//
//   if (includeInstance) {
//     obj.$jspbMessageInstance = msg;
//   }
//   return obj;
// };
// }
//
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.deserializeBinary = function(bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest;
//   return proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.deserializeBinaryFromReader(msg, reader);
// };
//
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//     case 1:
//       var value = /** @type {number} */ (reader.readInt32());
//       msg.setPage(value);
//       break;
//     case 2:
//       var value = /** @type {number} */ (reader.readInt32());
//       msg.setPerPage(value);
//       break;
//     case 3:
//       var value = /** @type {string} */ (reader.readString());
//       msg.setSort(value);
//       break;
//     case 4:
//       var value = /** @type {string} */ (reader.readString());
//       msg.setSortColumn(value);
//       break;
//     case 5:
//       var value = /** @type {string} */ (reader.readString());
//       msg.setSearch(value);
//       break;
//     case 6:
//       var value = /** @type {boolean} */ (reader.readBool());
//       msg.setNonorderedOnly(value);
//       break;
//     case 7:
//       var value = /** @type {string} */ (reader.readString());
//       msg.setTerminalName(value);
//       break;
//     case 8:
//       var value = /** @type {boolean} */ (reader.readBool());
//       msg.setCancelledOnly(value);
//       break;
//     default:
//       reader.skipField();
//       break;
//     }
//   }
//   return msg;
// };
//
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.serializeBinary = function() {
//   var writer = new jspb.BinaryWriter();
//   proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
//   var f = undefined;
//   f = message.getPage();
//   if (f !== 0) {
//     writer.writeInt32(
//       1,
//       f
//     );
//   }
//   f = message.getPerPage();
//   if (f !== 0) {
//     writer.writeInt32(
//       2,
//       f
//     );
//   }
//   f = message.getSort();
//   if (f.length > 0) {
//     writer.writeString(
//       3,
//       f
//     );
//   }
//   f = message.getSortColumn();
//   if (f.length > 0) {
//     writer.writeString(
//       4,
//       f
//     );
//   }
//   f = message.getSearch();
//   if (f.length > 0) {
//     writer.writeString(
//       5,
//       f
//     );
//   }
//   f = message.getNonorderedOnly();
//   if (f) {
//     writer.writeBool(
//       6,
//       f
//     );
//   }
//   f = message.getTerminalName();
//   if (f.length > 0) {
//     writer.writeString(
//       7,
//       f
//     );
//   }
//   f = message.getCancelledOnly();
//   if (f) {
//     writer.writeBool(
//       8,
//       f
//     );
//   }
// };
//
//
// /**
//  * optional int32 page = 1;
//  * @return {number}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getPage = function() {
//   return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
// };
//
//
// /**
//  * @param {number} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setPage = function(value) {
//   return jspb.Message.setProto3IntField(this, 1, value);
// };
//
//
// /**
//  * optional int32 per_page = 2;
//  * @return {number}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getPerPage = function() {
//   return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
// };
//
//
// /**
//  * @param {number} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setPerPage = function(value) {
//   return jspb.Message.setProto3IntField(this, 2, value);
// };
//
//
// /**
//  * optional string sort = 3;
//  * @return {string}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getSort = function() {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
// };
//
//
// /**
//  * @param {string} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setSort = function(value) {
//   return jspb.Message.setProto3StringField(this, 3, value);
// };
//
//
// /**
//  * optional string sort_column = 4;
//  * @return {string}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getSortColumn = function() {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
// };
//
//
// /**
//  * @param {string} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setSortColumn = function(value) {
//   return jspb.Message.setProto3StringField(this, 4, value);
// };
//
//
// /**
//  * optional string search = 5;
//  * @return {string}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getSearch = function() {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
// };
//
//
// /**
//  * @param {string} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setSearch = function(value) {
//   return jspb.Message.setProto3StringField(this, 5, value);
// };
//
//
// /**
//  * optional bool nonordered_only = 6;
//  * @return {boolean}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getNonorderedOnly = function() {
//   return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
// };
//
//
// /**
//  * @param {boolean} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setNonorderedOnly = function(value) {
//   return jspb.Message.setProto3BooleanField(this, 6, value);
// };
//
//
// /**
//  * optional string terminal_name = 7;
//  * @return {string}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getTerminalName = function() {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
// };
//
//
// /**
//  * @param {string} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setTerminalName = function(value) {
//   return jspb.Message.setProto3StringField(this, 7, value);
// };
//
//
// /**
//  * optional bool cancelled_only = 8;
//  * @return {boolean}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getCancelledOnly = function() {
//   return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
// };
//
//
// /**
//  * @param {boolean} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setCancelledOnly = function(value) {
//   return jspb.Message.setProto3BooleanField(this, 8, value);
// };
//
//
//
// /**
//  * List of repeated fields within this message type.
//  * @private {!Array<number>}
//  * @const
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.repeatedFields_ = [1];
//
//
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
// /**
//  * Creates an object representation of this proto.
//  * Field names that are reserved in JavaScript and will be renamed to pb_name.
//  * Optional fields that are not set will be set to undefined.
//  * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//  * For the list of reserved names please see:
//  *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
//  * @param {boolean=} opt_includeInstance Deprecated. whether to include the
//  *     JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @return {!Object}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
//   return proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.toObject(opt_includeInstance, this);
// };
//
//
// /**
//  * Static version of the {@see toObject} method.
//  * @param {boolean|undefined} includeInstance Deprecated. Whether to include
//  *     the JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} msg The msg instance to transform.
//  * @return {!Object}
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.toObject = function(includeInstance, msg) {
//   var f, obj = {
//     vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
//     projects_erp_service_api_v1_model_vehicle_pb.Vehicle.toObject, includeInstance),
//     totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
//     totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0)
//   };
//
//   if (includeInstance) {
//     obj.$jspbMessageInstance = msg;
//   }
//   return obj;
// };
// }
//
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinary = function(bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse;
//   return proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinaryFromReader(msg, reader);
// };
//
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//     case 1:
//       var value = new projects_erp_service_api_v1_model_vehicle_pb.Vehicle;
//       reader.readMessage(value,projects_erp_service_api_v1_model_vehicle_pb.Vehicle.deserializeBinaryFromReader);
//       msg.addVehicles(value);
//       break;
//     case 2:
//       var value = /** @type {number} */ (reader.readInt64());
//       msg.setTotalRows(value);
//       break;
//     case 3:
//       var value = /** @type {number} */ (reader.readInt32());
//       msg.setTotalPages(value);
//       break;
//     default:
//       reader.skipField();
//       break;
//     }
//   }
//   return msg;
// };
//
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.serializeBinary = function() {
//   var writer = new jspb.BinaryWriter();
//   proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
//   var f = undefined;
//   f = message.getVehiclesList();
//   if (f.length > 0) {
//     writer.writeRepeatedMessage(
//       1,
//       f,
//       projects_erp_service_api_v1_model_vehicle_pb.Vehicle.serializeBinaryToWriter
//     );
//   }
//   f = message.getTotalRows();
//   if (f !== 0) {
//     writer.writeInt64(
//       2,
//       f
//     );
//   }
//   f = message.getTotalPages();
//   if (f !== 0) {
//     writer.writeInt32(
//       3,
//       f
//     );
//   }
// };
//
//
// /**
//  * repeated mycargo.projects.erp.api.v1.model.Vehicle vehicles = 1;
//  * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.Vehicle>}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.getVehiclesList = function() {
//   return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.Vehicle>} */ (
//     jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_vehicle_pb.Vehicle, 1));
// };
//
//
// /**
//  * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.Vehicle>} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
// */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.setVehiclesList = function(value) {
//   return jspb.Message.setRepeatedWrapperField(this, 1, value);
// };
//
//
// /**
//  * @param {!proto.mycargo.projects.erp.api.v1.model.Vehicle=} opt_value
//  * @param {number=} opt_index
//  * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.addVehicles = function(opt_value, opt_index) {
//   return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mycargo.projects.erp.api.v1.model.Vehicle, opt_index);
// };
//
//
// /**
//  * Clears the list making it empty but non-null.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.clearVehiclesList = function() {
//   return this.setVehiclesList([]);
// };
//
//
// /**
//  * optional int64 total_rows = 2;
//  * @return {number}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.getTotalRows = function() {
//   return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
// };
//
//
// /**
//  * @param {number} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.setTotalRows = function(value) {
//   return jspb.Message.setProto3IntField(this, 2, value);
// };
//
//
// /**
//  * optional int32 total_pages = 3;
//  * @return {number}
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.getTotalPages = function() {
//   return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
// };
//
//
// /**
//  * @param {number} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.setTotalPages = function(value) {
//   return jspb.Message.setProto3IntField(this, 3, value);
// };
//
//
//
//
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
// /**
//  * Creates an object representation of this proto.
//  * Field names that are reserved in JavaScript and will be renamed to pb_name.
//  * Optional fields that are not set will be set to undefined.
//  * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//  * For the list of reserved names please see:
//  *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
//  * @param {boolean=} opt_includeInstance Deprecated. whether to include the
//  *     JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @return {!Object}
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.toObject = function(opt_includeInstance) {
//   return proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.toObject(opt_includeInstance, this);
// };
//
//
// /**
//  * Static version of the {@see toObject} method.
//  * @param {boolean|undefined} includeInstance Deprecated. Whether to include
//  *     the JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} msg The msg instance to transform.
//  * @return {!Object}
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.toObject = function(includeInstance, msg) {
//   var f, obj = {
//     name: jspb.Message.getFieldWithDefault(msg, 1, "")
//   };
//
//   if (includeInstance) {
//     obj.$jspbMessageInstance = msg;
//   }
//   return obj;
// };
// }
//
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest}
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.deserializeBinary = function(bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest;
//   return proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.deserializeBinaryFromReader(msg, reader);
// };
//
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest}
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//     case 1:
//       var value = /** @type {string} */ (reader.readString());
//       msg.setName(value);
//       break;
//     default:
//       reader.skipField();
//       break;
//     }
//   }
//   return msg;
// };
//
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.serializeBinary = function() {
//   var writer = new jspb.BinaryWriter();
//   proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.serializeBinaryToWriter = function(message, writer) {
//   var f = undefined;
//   f = message.getName();
//   if (f.length > 0) {
//     writer.writeString(
//       1,
//       f
//     );
//   }
// };
//
//
// /**
//  * optional string name = 1;
//  * @return {string}
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.getName = function() {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
// };
//
//
// /**
//  * @param {string} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.setName = function(value) {
//   return jspb.Message.setProto3StringField(this, 1, value);
// };
//
//
//
//
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
// /**
//  * Creates an object representation of this proto.
//  * Field names that are reserved in JavaScript and will be renamed to pb_name.
//  * Optional fields that are not set will be set to undefined.
//  * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//  * For the list of reserved names please see:
//  *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
//  * @param {boolean=} opt_includeInstance Deprecated. whether to include the
//  *     JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @return {!Object}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.toObject = function(opt_includeInstance) {
//   return proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.toObject(opt_includeInstance, this);
// };
//
//
// /**
//  * Static version of the {@see toObject} method.
//  * @param {boolean|undefined} includeInstance Deprecated. Whether to include
//  *     the JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} msg The msg instance to transform.
//  * @return {!Object}
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.toObject = function(includeInstance, msg) {
//   var f, obj = {
//     name: jspb.Message.getFieldWithDefault(msg, 1, ""),
//     vehicle: (f = msg.getVehicle()) && proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.toObject(includeInstance, f)
//   };
//
//   if (includeInstance) {
//     obj.$jspbMessageInstance = msg;
//   }
//   return obj;
// };
// }
//
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.deserializeBinary = function(bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest;
//   return proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.deserializeBinaryFromReader(msg, reader);
// };
//
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//     case 1:
//       var value = /** @type {string} */ (reader.readString());
//       msg.setName(value);
//       break;
//     case 2:
//       var value = new proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload;
//       reader.readMessage(value,proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinaryFromReader);
//       msg.setVehicle(value);
//       break;
//     default:
//       reader.skipField();
//       break;
//     }
//   }
//   return msg;
// };
//
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.serializeBinary = function() {
//   var writer = new jspb.BinaryWriter();
//   proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.serializeBinaryToWriter = function(message, writer) {
//   var f = undefined;
//   f = message.getName();
//   if (f.length > 0) {
//     writer.writeString(
//       1,
//       f
//     );
//   }
//   f = message.getVehicle();
//   if (f != null) {
//     writer.writeMessage(
//       2,
//       f,
//       proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.serializeBinaryToWriter
//     );
//   }
// };
//
//
// /**
//  * optional string name = 1;
//  * @return {string}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.getName = function() {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
// };
//
//
// /**
//  * @param {string} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.setName = function(value) {
//   return jspb.Message.setProto3StringField(this, 1, value);
// };
//
//
// /**
//  * optional UpdateVehiclePayload vehicle = 2;
//  * @return {?proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.getVehicle = function() {
//   return /** @type{?proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} */ (
//     jspb.Message.getWrapperField(this, proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload, 2));
// };
//
//
// /**
//  * @param {?proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload|undefined} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} returns this
// */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.setVehicle = function(value) {
//   return jspb.Message.setWrapperField(this, 2, value);
// };
//
//
// /**
//  * Clears the message field making it undefined.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.clearVehicle = function() {
//   return this.setVehicle(undefined);
// };
//
//
// /**
//  * Returns whether this field is set.
//  * @return {boolean}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.hasVehicle = function() {
//   return jspb.Message.getField(this, 2) != null;
// };
//
//
//
//
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
// /**
//  * Creates an object representation of this proto.
//  * Field names that are reserved in JavaScript and will be renamed to pb_name.
//  * Optional fields that are not set will be set to undefined.
//  * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//  * For the list of reserved names please see:
//  *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
//  * @param {boolean=} opt_includeInstance Deprecated. whether to include the
//  *     JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @return {!Object}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.toObject = function(opt_includeInstance) {
//   return proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.toObject(opt_includeInstance, this);
// };
//
//
// /**
//  * Static version of the {@see toObject} method.
//  * @param {boolean|undefined} includeInstance Deprecated. Whether to include
//  *     the JSPB instance for transitional soy proto support:
//  *     http://goto/soy-param-migration
//  * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} msg The msg instance to transform.
//  * @return {!Object}
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.toObject = function(includeInstance, msg) {
//   var f, obj = {
//     cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
//   };
//
//   if (includeInstance) {
//     obj.$jspbMessageInstance = msg;
//   }
//   return obj;
// };
// }
//
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinary = function(bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload;
//   return proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinaryFromReader(msg, reader);
// };
//
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinaryFromReader = function(msg, reader) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//     case 1:
//       var value = /** @type {boolean} */ (reader.readBool());
//       msg.setCancelled(value);
//       break;
//     default:
//       reader.skipField();
//       break;
//     }
//   }
//   return msg;
// };
//
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.serializeBinary = function() {
//   var writer = new jspb.BinaryWriter();
//   proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.serializeBinaryToWriter = function(message, writer) {
//   var f = undefined;
//   f = message.getCancelled();
//   if (f) {
//     writer.writeBool(
//       1,
//       f
//     );
//   }
// };
//
//
// /**
//  * optional bool cancelled = 1;
//  * @return {boolean}
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.getCancelled = function() {
//   return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
// };
//
//
// /**
//  * @param {boolean} value
//  * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} returns this
//  */
// proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.setCancelled = function(value) {
//   return jspb.Message.setProto3BooleanField(this, 1, value);
// };
//
//
// goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.services);

/******************************************** DELETE FROM HERE ***********************************************/

// source: projects/erp/service/api/v1/services/vehicles.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf")
var goog = jspb
var global = function () {
    return this || window || global || self || Function("return this")()
}.call(null)

var projects_erp_service_api_v1_model_vehicle_pb = require("./vehicle_pb.js")
goog.object.extend(proto, projects_erp_service_api_v1_model_vehicle_pb)
goog.exportSymbol(
    "proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest",
    null,
    global,
)
goog.exportSymbol(
    "proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest",
    null,
    global,
)
goog.exportSymbol(
    "proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse",
    null,
    global,
)
goog.exportSymbol(
    "proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload",
    null,
    global,
)
goog.exportSymbol(
    "proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest",
    null,
    global,
)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest = function (
    opt_data,
) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest,
    jspb.Message,
)
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.displayName =
        "proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest"
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse = function (
    opt_data,
) {
    jspb.Message.initialize(
        this,
        opt_data,
        0,
        -1,
        proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse
            .repeatedFields_,
        null,
    )
}
goog.inherits(
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse,
    jspb.Message,
)
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.displayName =
        "proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse"
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest = function (
    opt_data,
) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(
    proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest,
    jspb.Message,
)
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.displayName =
        "proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest"
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest = function (
    opt_data,
) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(
    proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest,
    jspb.Message,
)
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.displayName =
        "proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest"
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload = function (
    opt_data,
) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(
    proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload,
    jspb.Message,
)
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.displayName =
        "proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload"
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.toObject =
        function (opt_includeInstance) {
            return proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.toObject(
                opt_includeInstance,
                this,
            )
        }

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.toObject =
        function (includeInstance, msg) {
            var f,
                obj = {
                    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
                    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
                    sort: jspb.Message.getFieldWithDefault(msg, 3, ""),
                    sortColumn: jspb.Message.getFieldWithDefault(msg, 4, ""),
                    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
                    nonorderedOnly: jspb.Message.getBooleanFieldWithDefault(
                        msg,
                        6,
                        false,
                    ),
                    terminalName: jspb.Message.getFieldWithDefault(msg, 7, ""),
                    cancelledOnly: jspb.Message.getBooleanFieldWithDefault(
                        msg,
                        8,
                        false,
                    ),
                }

            if (includeInstance) {
                obj.$jspbMessageInstance = msg
            }
            return obj
        }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.deserializeBinary =
    function (bytes) {
        var reader = new jspb.BinaryReader(bytes)
        var msg =
            new proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest()
        return proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.deserializeBinaryFromReader(
            msg,
            reader,
        )
    }

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.deserializeBinaryFromReader =
    function (msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break
            }
            var field = reader.getFieldNumber()
            switch (field) {
                case 1:
                    var value = /** @type {number} */ (reader.readInt32())
                    msg.setPage(value)
                    break
                case 2:
                    var value = /** @type {number} */ (reader.readInt32())
                    msg.setPerPage(value)
                    break
                case 3:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setSort(value)
                    break
                case 4:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setSortColumn(value)
                    break
                case 5:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setSearch(value)
                    break
                case 6:
                    var value = /** @type {boolean} */ (reader.readBool())
                    msg.setNonorderedOnly(value)
                    break
                case 7:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setTerminalName(value)
                    break
                case 8:
                    var value = /** @type {boolean} */ (reader.readBool())
                    msg.setCancelledOnly(value)
                    break
                default:
                    reader.skipField()
                    break
            }
        }
        return msg
    }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.serializeBinary =
    function () {
        var writer = new jspb.BinaryWriter()
        proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.serializeBinaryToWriter(
            this,
            writer,
        )
        return writer.getResultBuffer()
    }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.serializeBinaryToWriter =
    function (message, writer) {
        var f = undefined
        f = message.getPage()
        if (f !== 0) {
            writer.writeInt32(1, f)
        }
        f = message.getPerPage()
        if (f !== 0) {
            writer.writeInt32(2, f)
        }
        f = message.getSort()
        if (f.length > 0) {
            writer.writeString(3, f)
        }
        f = message.getSortColumn()
        if (f.length > 0) {
            writer.writeString(4, f)
        }
        f = message.getSearch()
        if (f.length > 0) {
            writer.writeString(5, f)
        }
        f = message.getNonorderedOnly()
        if (f) {
            writer.writeBool(6, f)
        }
        f = message.getTerminalName()
        if (f.length > 0) {
            writer.writeString(7, f)
        }
        f = message.getCancelledOnly()
        if (f) {
            writer.writeBool(8, f)
        }
    }

/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getPage =
    function () {
        return /** @type {number} */ (
            jspb.Message.getFieldWithDefault(this, 1, 0)
        )
    }

/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setPage =
    function (value) {
        return jspb.Message.setProto3IntField(this, 1, value)
    }

/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getPerPage =
    function () {
        return /** @type {number} */ (
            jspb.Message.getFieldWithDefault(this, 2, 0)
        )
    }

/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setPerPage =
    function (value) {
        return jspb.Message.setProto3IntField(this, 2, value)
    }

/**
 * optional string sort = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getSort =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 3, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setSort =
    function (value) {
        return jspb.Message.setProto3StringField(this, 3, value)
    }

/**
 * optional string sort_column = 4;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getSortColumn =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 4, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setSortColumn =
    function (value) {
        return jspb.Message.setProto3StringField(this, 4, value)
    }

/**
 * optional string search = 5;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getSearch =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 5, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setSearch =
    function (value) {
        return jspb.Message.setProto3StringField(this, 5, value)
    }

/**
 * optional bool nonordered_only = 6;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getNonorderedOnly =
    function () {
        return /** @type {boolean} */ (
            jspb.Message.getBooleanFieldWithDefault(this, 6, false)
        )
    }

/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setNonorderedOnly =
    function (value) {
        return jspb.Message.setProto3BooleanField(this, 6, value)
    }

/**
 * optional string terminal_name = 7;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getTerminalName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 7, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setTerminalName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 7, value)
    }

/**
 * optional bool cancelled_only = 8;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.getCancelledOnly =
    function () {
        return /** @type {boolean} */ (
            jspb.Message.getBooleanFieldWithDefault(this, 8, false)
        )
    }

/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesRequest.prototype.setCancelledOnly =
    function (value) {
        return jspb.Message.setProto3BooleanField(this, 8, value)
    }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.repeatedFields_ =
    [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.toObject =
        function (opt_includeInstance) {
            return proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.toObject(
                opt_includeInstance,
                this,
            )
        }

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.toObject =
        function (includeInstance, msg) {
            var f,
                obj = {
                    vehiclesList: jspb.Message.toObjectList(
                        msg.getVehiclesList(),
                        projects_erp_service_api_v1_model_vehicle_pb.Vehicle
                            .toObject,
                        includeInstance,
                    ),
                    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
                    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0),
                }

            if (includeInstance) {
                obj.$jspbMessageInstance = msg
            }
            return obj
        }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinary =
    function (bytes) {
        var reader = new jspb.BinaryReader(bytes)
        var msg =
            new proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse()
        return proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinaryFromReader(
            msg,
            reader,
        )
    }

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.deserializeBinaryFromReader =
    function (msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break
            }
            var field = reader.getFieldNumber()
            switch (field) {
                case 1:
                    var value =
                        new projects_erp_service_api_v1_model_vehicle_pb.Vehicle()
                    reader.readMessage(
                        value,
                        projects_erp_service_api_v1_model_vehicle_pb.Vehicle
                            .deserializeBinaryFromReader,
                    )
                    msg.addVehicles(value)
                    break
                case 2:
                    var value = /** @type {number} */ (reader.readInt64())
                    msg.setTotalRows(value)
                    break
                case 3:
                    var value = /** @type {number} */ (reader.readInt32())
                    msg.setTotalPages(value)
                    break
                default:
                    reader.skipField()
                    break
            }
        }
        return msg
    }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.serializeBinary =
    function () {
        var writer = new jspb.BinaryWriter()
        proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.serializeBinaryToWriter(
            this,
            writer,
        )
        return writer.getResultBuffer()
    }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.serializeBinaryToWriter =
    function (message, writer) {
        var f = undefined
        f = message.getVehiclesList()
        if (f.length > 0) {
            writer.writeRepeatedMessage(
                1,
                f,
                projects_erp_service_api_v1_model_vehicle_pb.Vehicle
                    .serializeBinaryToWriter,
            )
        }
        f = message.getTotalRows()
        if (f !== 0) {
            writer.writeInt64(2, f)
        }
        f = message.getTotalPages()
        if (f !== 0) {
            writer.writeInt32(3, f)
        }
    }

/**
 * repeated mycargo.projects.erp.api.v1.model.Vehicle vehicles = 1;
 * @return {!Array<!proto.mycargo.projects.erp.api.v1.model.Vehicle>}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.getVehiclesList =
    function () {
        return /** @type{!Array<!proto.mycargo.projects.erp.api.v1.model.Vehicle>} */ (
            jspb.Message.getRepeatedWrapperField(
                this,
                projects_erp_service_api_v1_model_vehicle_pb.Vehicle,
                1,
            )
        )
    }

/**
 * @param {!Array<!proto.mycargo.projects.erp.api.v1.model.Vehicle>} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.setVehiclesList =
    function (value) {
        return jspb.Message.setRepeatedWrapperField(this, 1, value)
    }

/**
 * @param {!proto.mycargo.projects.erp.api.v1.model.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mycargo.projects.erp.api.v1.model.Vehicle}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.addVehicles =
    function (opt_value, opt_index) {
        return jspb.Message.addToRepeatedWrapperField(
            this,
            1,
            opt_value,
            proto.mycargo.projects.erp.api.v1.model.Vehicle,
            opt_index,
        )
    }

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.clearVehiclesList =
    function () {
        return this.setVehiclesList([])
    }

/**
 * optional int64 total_rows = 2;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.getTotalRows =
    function () {
        return /** @type {number} */ (
            jspb.Message.getFieldWithDefault(this, 2, 0)
        )
    }

/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.setTotalRows =
    function (value) {
        return jspb.Message.setProto3IntField(this, 2, value)
    }

/**
 * optional int32 total_pages = 3;
 * @return {number}
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.getTotalPages =
    function () {
        return /** @type {number} */ (
            jspb.Message.getFieldWithDefault(this, 3, 0)
        )
    }

/**
 * @param {number} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse} returns this
 */
proto.mycargo.projects.erp.api.v1.services.ListVehiclesResponse.prototype.setTotalPages =
    function (value) {
        return jspb.Message.setProto3IntField(this, 3, value)
    }

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.toObject =
        function (opt_includeInstance) {
            return proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.toObject(
                opt_includeInstance,
                this,
            )
        }

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.toObject =
        function (includeInstance, msg) {
            var f,
                obj = {
                    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                }

            if (includeInstance) {
                obj.$jspbMessageInstance = msg
            }
            return obj
        }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest}
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.deserializeBinary =
    function (bytes) {
        var reader = new jspb.BinaryReader(bytes)
        var msg =
            new proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest()
        return proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.deserializeBinaryFromReader(
            msg,
            reader,
        )
    }

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest}
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.deserializeBinaryFromReader =
    function (msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break
            }
            var field = reader.getFieldNumber()
            switch (field) {
                case 1:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setName(value)
                    break
                default:
                    reader.skipField()
                    break
            }
        }
        return msg
    }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.serializeBinary =
    function () {
        var writer = new jspb.BinaryWriter()
        proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.serializeBinaryToWriter(
            this,
            writer,
        )
        return writer.getResultBuffer()
    }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.serializeBinaryToWriter =
    function (message, writer) {
        var f = undefined
        f = message.getName()
        if (f.length > 0) {
            writer.writeString(1, f)
        }
    }

/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.getName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 1, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.GetVehicleRequest.prototype.setName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 1, value)
    }

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.toObject =
        function (opt_includeInstance) {
            return proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.toObject(
                opt_includeInstance,
                this,
            )
        }

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.toObject =
        function (includeInstance, msg) {
            var f,
                obj = {
                    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                    vehicle:
                        (f = msg.getVehicle()) &&
                        proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.toObject(
                            includeInstance,
                            f,
                        ),
                }

            if (includeInstance) {
                obj.$jspbMessageInstance = msg
            }
            return obj
        }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.deserializeBinary =
    function (bytes) {
        var reader = new jspb.BinaryReader(bytes)
        var msg =
            new proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest()
        return proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.deserializeBinaryFromReader(
            msg,
            reader,
        )
    }

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.deserializeBinaryFromReader =
    function (msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break
            }
            var field = reader.getFieldNumber()
            switch (field) {
                case 1:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setName(value)
                    break
                case 2:
                    var value =
                        new proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload()
                    reader.readMessage(
                        value,
                        proto.mycargo.projects.erp.api.v1.services
                            .UpdateVehiclePayload.deserializeBinaryFromReader,
                    )
                    msg.setVehicle(value)
                    break
                default:
                    reader.skipField()
                    break
            }
        }
        return msg
    }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.serializeBinary =
    function () {
        var writer = new jspb.BinaryWriter()
        proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.serializeBinaryToWriter(
            this,
            writer,
        )
        return writer.getResultBuffer()
    }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.serializeBinaryToWriter =
    function (message, writer) {
        var f = undefined
        f = message.getName()
        if (f.length > 0) {
            writer.writeString(1, f)
        }
        f = message.getVehicle()
        if (f != null) {
            writer.writeMessage(
                2,
                f,
                proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload
                    .serializeBinaryToWriter,
            )
        }
    }

/**
 * optional string name = 1;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.getName =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 1, "")
        )
    }

/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.setName =
    function (value) {
        return jspb.Message.setProto3StringField(this, 1, value)
    }

/**
 * optional UpdateVehiclePayload vehicle = 2;
 * @return {?proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.getVehicle =
    function () {
        return /** @type{?proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} */ (
            jspb.Message.getWrapperField(
                this,
                proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload,
                2,
            )
        )
    }

/**
 * @param {?proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload|undefined} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.setVehicle =
    function (value) {
        return jspb.Message.setWrapperField(this, 2, value)
    }

/**
 * Clears the message field making it undefined.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.clearVehicle =
    function () {
        return this.setVehicle(undefined)
    }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehicleRequest.prototype.hasVehicle =
    function () {
        return jspb.Message.getField(this, 2) != null
    }

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.toObject =
        function (opt_includeInstance) {
            return proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.toObject(
                opt_includeInstance,
                this,
            )
        }

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.toObject =
        function (includeInstance, msg) {
            var f,
                obj = {
                    cancelled: jspb.Message.getBooleanFieldWithDefault(
                        msg,
                        1,
                        false,
                    ),
                    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
                    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
                }

            if (includeInstance) {
                obj.$jspbMessageInstance = msg
            }
            return obj
        }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinary =
    function (bytes) {
        var reader = new jspb.BinaryReader(bytes)
        var msg =
            new proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload()
        return proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinaryFromReader(
            msg,
            reader,
        )
    }

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.deserializeBinaryFromReader =
    function (msg, reader) {
        while (reader.nextField()) {
            if (reader.isEndGroup()) {
                break
            }
            var field = reader.getFieldNumber()
            switch (field) {
                case 1:
                    var value = /** @type {boolean} */ (reader.readBool())
                    msg.setCancelled(value)
                    break
                case 2:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setDescription(value)
                    break
                case 3:
                    var value = /** @type {string} */ (reader.readString())
                    msg.setVin(value)
                    break
                default:
                    reader.skipField()
                    break
            }
        }
        return msg
    }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.serializeBinary =
    function () {
        var writer = new jspb.BinaryWriter()
        proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.serializeBinaryToWriter(
            this,
            writer,
        )
        return writer.getResultBuffer()
    }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.serializeBinaryToWriter =
    function (message, writer) {
        var f = undefined
        f = message.getCancelled()
        if (f) {
            writer.writeBool(1, f)
        }
        f = message.getDescription()
        if (f.length > 0) {
            writer.writeString(2, f)
        }
        f = message.getVin()
        if (f.length > 0) {
            writer.writeString(3, f)
        }
    }

/**
 * optional bool cancelled = 1;
 * @return {boolean}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.getCancelled =
    function () {
        return /** @type {boolean} */ (
            jspb.Message.getBooleanFieldWithDefault(this, 1, false)
        )
    }

/**
 * @param {boolean} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.setCancelled =
    function (value) {
        return jspb.Message.setProto3BooleanField(this, 1, value)
    }

/**
 * optional string description = 2;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.getDescription =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 2, "")
        )
    }
/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.setDescription =
    function (value) {
        return jspb.Message.setProto3StringField(this, 2, value)
    }
/**
 * optional string vin = 3;
 * @return {string}
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.getVin =
    function () {
        return /** @type {string} */ (
            jspb.Message.getFieldWithDefault(this, 3, "")
        )
    }
/**
 * @param {string} value
 * @return {!proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload} returns this
 */
proto.mycargo.projects.erp.api.v1.services.UpdateVehiclePayload.prototype.setVin =
    function (value) {
        return jspb.Message.setProto3StringField(this, 3, value)
    }

goog.object.extend(exports, proto.mycargo.projects.erp.api.v1.services)
